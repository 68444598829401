import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactHtmlParser from 'react-html-parser'
import { isSafari } from 'react-device-detect'
import { formatDate } from '../utility/helpers'
import SEO from '../components/seo'
import { getParagraphs } from '../paragraphs/get-paragraphs'
import * as blogStyles from '../styles/modules/post.module.scss'
import { wysiwyg } from '../styles/modules/wysiwyg.module.scss'
import { transform } from '../paragraphs/text-paragraph'

const BlogPostTemplate = ({ data }) => {
  const post = data.nodeBlogPost
  const paragraphs = post.relationships.paragraphs.map(getParagraphs)
  const image = getImage(post.relationships.field_image.localFile)
  const imageAlt = post.field_image.alt || ''
  const imageUrl = `https://admin.redfinsolutions.com${post.relationships.field_image.uri.url}`
  const author = post.relationships.uid.name
  const byLine = author ? `by ${author} | ` : ''

  return (
    <>
      <SEO
        title={post.title}
        description={post.body.summary}
        image={imageUrl}
        imageAlt={imageAlt}
      />
      <div className={blogStyles.header}>
        <GatsbyImage image={image} alt={imageAlt} className={blogStyles.image} />
        <div className={blogStyles.info}>
          <h1>{post.title}</h1>
          <div className={blogStyles.byLine}>{byLine + formatDate(post.created)}</div>
        </div>
      </div>
      <article className={blogStyles.article}>
        <div className={wysiwyg}>
          <div className={isSafari ? 'safari' : 'not-safari'}>
            {ReactHtmlParser(post.body.processed, { transform })}
          </div>
          <div>{paragraphs}</div>
        </div>
      </article>
    </>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query ($id: String!) {
    nodeBlogPost(id: { eq: $id }) {
      title
      created
      id
      field_image {
        alt
      }
      relationships {
        uid {
          name
        }
        paragraphs: field_paragraphs {
          type: __typename
          ...TextParagraphQuery
          ...ImageWithCaptionParagraphQuery
        }
        field_image {
          uri {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      body {
        summary
        processed
      }
    }
  }
`
